import {puzzleServerClient} from "./PuzzleServerClient";

export function overrideConsoleLog() {
    // define a new console
    let console = (function (oldCons) {
        return {
            log: function (text) {
                oldCons.log(text);
                puzzleServerClient.log(text);
            },
            info: function (text) {
                oldCons.info(text);
                puzzleServerClient.log(text);
            },
            warn: function (text) {
                oldCons.warn(text);
                puzzleServerClient.log(text);
            },
            error: function (text) {
                oldCons.error(text);
                puzzleServerClient.log(text);
            }
        };
    }(window.console));

    // Then redefine the old console
    (window as any).console = console;
}
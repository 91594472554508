import * as React from "react";
import "./Popup.scss";
import {Button, ButtonGroup, Col, Container, Row} from "reactstrap";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {AnswerResponse} from "../client/PuzzleServerClient";

library.add(faWindowClose);

class PopupProps {
    text: string;
    answer: (s: string) => AnswerResponse;
    closePopup: () => void;
}

class PopupState {
    response?: string;
}

export default class Popup extends React.Component<PopupProps, PopupState> {

    constructor(props: PopupProps, context: any) {
        super(props, context);
        this.setState({response: ""});
    }

    answer = async (a: string) => {
        let response = await this.props.answer(a);
        if (response.ok) {
            this.setState({response: "Korrekt"});
        } else {
            if (response.questionState === "WRONG1") {
                this.setState({response: "Prøv igen"});
            } else {
                this.setState({response: "Øv, bøv. Forkert."});
            }

        }
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.props.closePopup();
    }

    render() {
        return <div className="popup">
            <div className="popup_inner">
                <Container>
                    <Row>
                        <Col sm={{size: 1, offset: 11}}>
                            <Button close onClick={this.props.closePopup}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <h3>{this.props.text}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <ButtonGroup className="btn-block">
                            <Col sm="4">
                                <Button color="primary" size="lg" block={true} onClick={() => this.answer("A")}>A</Button>
                            </Col>
                            <Col sm="4">
                                <Button color="primary" size="lg" block={true} onClick={() => this.answer("B")}>B</Button>
                            </Col>
                            <Col sm="4">
                                <Button color="primary" size="lg" block={true} onClick={() => this.answer("C")}>C</Button>
                            </Col>
                        </ButtonGroup>
                    </Row>
                    {this.state && this.state.response && <Row><Col sm="12"><h3>{this.state.response}</h3></Col></Row>}
                </Container>
            </div>
        </div>;
    }
}

import * as React from "react";
import "./App.scss";
import Puzzle from "./Puzzle";
import "bootstrap/dist/css/bootstrap.css";
import {DragDropContext} from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/lib/HTML5toTouch";
import * as Cookies from "js-cookie";
import {puzzleServerClient} from "../client/PuzzleServerClient";

const DragDropContextProvider = DragDropContext(MultiBackend(HTML5toTouch))(
    (props: any) => React.Children.only(props.children)
);

export interface AppProps {
}

export interface AppState {
    loadError: string;
}

let thisUrl = new URL(window.location.href);
let key = thisUrl.searchParams.get("key");
if (key) {
    Cookies.set("key", key, {expires: 365});
}

export default class App extends React.Component<AppProps, AppState> {
    async componentDidMount() {
        try {
            let user = await puzzleServerClient.getUser();
        } catch (e) {
            this.setState({loadError: e.toString()});
        }
    }

    render() {
        if (!Cookies.get("key")) {
            return <div>Du skal bruge linket fra emailen første gang.</div>;
        }
        if (this.state && this.state.loadError) {
            return <div>Ugyldig bruger ({this.state.loadError})</div>;
        }

        return (
            <DragDropContextProvider>
                <div>
                    <Puzzle/>
                </div>
            </DragDropContextProvider>

        );
    }
}

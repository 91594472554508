import * as React from "react";
import {PieceInfo} from "../state/PieceInfo";
import "./PuzzlePiece.scss";
import {
    ConnectDragSource,
    ConnectDropTarget,
    DragSource,
    DragSourceCollector,
    DragSourceConnector,
    DragSourceMonitor,
    DragSourceSpec,
    DropTarget,
    DropTargetCollector,
    DropTargetConnector,
    DropTargetMonitor,
    DropTargetSpec
} from "react-dnd";
import Popup from "./Popup";
import {AnswerFunction, SwitchPieceFunction} from "./Puzzle";
import {puzzleServerClient} from "../client/PuzzleServerClient";


export interface PuzzlePieceProps {
    connectDropTarget?: ConnectDropTarget;
    connectDragSource?: ConnectDragSource;
    piece?: PieceInfo;
    answer?: AnswerFunction;
    switchPieces?: SwitchPieceFunction;
    width?: number;
    height?: number;
}

const pieceSource: DragSourceSpec<PuzzlePieceProps, PuzzlePieceProps> = {
    beginDrag(props) {
        return props;
    }
};

const pieceTarget: DropTargetSpec<PuzzlePieceProps> = {
    drop(props, monitor) {
        const piece1 = props.piece;
        const piece2 = monitor.getItem().piece;

        props.switchPieces(piece1, piece2);
    }
};

const dragCollect: DragSourceCollector<PuzzlePieceProps> = (
    connect: DragSourceConnector,
    monitor: DragSourceMonitor,
) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
});

const dropCollect: DropTargetCollector<PuzzlePieceProps> = (
    connect: DropTargetConnector,
    monitor: DropTargetMonitor
) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
});

class PuzzlePieceState {
    showPopup: boolean;
}

export class PuzzlePiece extends React.Component<PuzzlePieceProps, PuzzlePieceState> {
    constructor(props: PuzzlePieceProps, context: any) {
        super(props, context);
        this.state = {
            showPopup: false
        };
    }

    showPopup = () => {
        if (this.props.piece.answerable) {
            this.setState({
                showPopup: true
            });
        }
    }

    hidePopup = () => {
        this.setState({
            showPopup: false
        });
    }

    answer = async (a: string) => {
        return await this.props.answer(this.props.piece, a);
    }

    render() {
        const width = this.props.width;
        const height = this.props.height;
        const divStyle = {
            backgroundImage: `url(${puzzleServerClient.getPieceUrl(this.props.piece.url, width, height)}`,
            width: `${width}px`,
            height: `${height}px`,
        };
        let res = <div>
            {this.props.piece.answerable ?
                <li className={"puzzle-piece"}
                    onClick={this.showPopup}
                    style={divStyle}>
                    {this.props.piece.answerable && <h1>{this.props.piece.date}</h1>}
                </li>
                :
                <li className={"puzzle-piece"}
                    style={divStyle}/>
            }
            {this.state.showPopup ?
                <Popup
                    text={`${this.props.piece.date}. december`}
                    answer={this.answer.bind(this)}
                    closePopup={this.hidePopup.bind(this)}
                />
                : null
            }
        </div>;
        if (this.props.connectDragSource)
            res = this.props.connectDragSource(res);

        if (this.props.connectDropTarget)
            res = this.props.connectDropTarget(res);

        return res;
    }
}

export default DropTarget("piece", pieceTarget, dropCollect)(DragSource("piece", pieceSource, dragCollect)(PuzzlePiece));

import * as React from "react";
import PuzzlePiece from "./PuzzlePiece";
import "./Puzzle.scss";
import {PieceInfo} from "../state/PieceInfo";
import {AnswerResponse, puzzleServerClient} from "../client/PuzzleServerClient";
import {PuzzleInfo} from "../state/PuzzleInfo";
import {Preview} from "react-dnd-multi-backend";

const objectAssign = require("object-assign");

export type AnswerFunction = (piece: PieceInfo, a: string) => Promise<AnswerResponse>;
export type SwitchPieceFunction = (piece1: PieceInfo, piece2: PieceInfo) => Promise<void>;

export interface PuzzleProps {
}

export class PuzzleState {
    info: PuzzleInfo;
    pieces: PieceInfo[];
    width: number;
    height: number;
    solved: boolean;
    showSecret: boolean;
    secretUrl: string;
}

export default class Puzzle extends React.Component<PuzzleProps, PuzzleState> {
    jsonToState(json: any): PuzzleState {
        return {
            info: {
                xSize: json.xsize,
                ySize: json.ysize
            },
            pieces: json.pieces,
            solved: json.solved,
            secretUrl: json.secretUrl
        } as PuzzleState;
    }

    componentDidMount(): void {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
        puzzleServerClient.getPuzzle()
            .then(json => {
                let state = this.jsonToState(json);
                this.setState(state);
            });
    }

    componentWillUnmount(): void {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({width: window.innerWidth, height: window.innerHeight});
    }

    switchPieces = async (piece1: PieceInfo, piece2: PieceInfo) => {
        let json = await puzzleServerClient.switchPieces(piece1, piece2);
        let state = this.jsonToState(json);
        this.setState(state);
    }

    answer = async (piece: PieceInfo, a: string) => {
        let json = await puzzleServerClient.answer(piece, a);
        let puzzleState = this.jsonToState(json.puzzle);
        this.setState(puzzleState);
        return json;
    }

    generatePreview = (type, item, style) => {
        objectAssign(style, {
                color: "white",
                backgroundImage: `url(${puzzleServerClient.getPieceUrl(item.piece.url, item.width, item.height)}`,
                width: item.width,
                height: item.height
            }
        );
        return <div style={style}>{item.piece.answerable && <h1>{item.piece.date}</h1>}</div>;
    }

    toggleSecret = () => {
        this.setState({showSecret: !this.state.showSecret});
    }

    render() {
        if (!this.state || !this.state.info) {
            return null;
        }
        let maxPieceHeight = Math.floor(this.state.height / this.state.info.ySize);
        let maxPieceWidth = Math.floor(this.state.width / this.state.info.xSize);
        let pieceSize = Math.min(maxPieceHeight, maxPieceWidth);
        let divStyle = {
            width: `${pieceSize * this.state.info.xSize}px`,
            height: `${pieceSize * this.state.info.ySize}px`,
        };
        return <div>
            <div className="col-lg-5">
                {!this.state.showSecret &&
                    <ul className="grid" style={divStyle}>
                        {this.state.pieces.map(((value, index) => <PuzzlePiece key={index} piece={value}
                                                                               height={pieceSize} width={pieceSize}
                                                                               answer={this.answer}
                                                                               switchPieces={this.switchPieces}/>))}
                        <Preview generator={this.generatePreview}/>
                    </ul>
                }
                {this.state.showSecret &&
                    <img style={divStyle} src={puzzleServerClient.getSecretUrl(this.state.secretUrl)}/>
                }
            </div>
            {this.state.solved &&
                <div style={{position: "absolute", top: 0, right: pieceSize/8, zIndex: 10, width: pieceSize / 4, height: pieceSize / 4}}>
                    <img src="christmas-gift-icon.png"
                         height={pieceSize / 4}
                         width={pieceSize / 4}
                         onClick={this.toggleSecret}/>
                </div>}
        </div>;
    }
}

import {PieceInfo} from "../state/PieceInfo";
import * as Cookies from "js-cookie";

export class PuzzleServerClient {
//     baseUrl = "http://localhost:8082";
    baseUrl = `${document.location.protocol}//${document.location.host}`;

    getKey(): string {
        return Cookies.get("key");
    }

    async log(message: string) {
        await fetch(`${this.baseUrl}/debug/log`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "text/plain"
            },
            method: "POST",
            body: message
        });
    }

    async getPuzzle() {
        let response = await fetch(`${this.baseUrl}/puzzle?key=${this.getKey()}`);
        let json = await response.json();
        return json;
    }

    async getUser() {
        let response = await fetch(`${this.baseUrl}/users/current?key=${this.getKey()}`);
        let json = await response.json();
        return json;
    }

    async switchPieces(piece1: PieceInfo, piece2: PieceInfo) {
        let response = await fetch(`${this.baseUrl}/puzzle/switchPieces?piece1=${piece1.date}&piece2=${piece2.date}&key=${this.getKey()}`);
        let json = await response.json();
        return json;
    }

    async answer(piece: PieceInfo, answer: string): Promise<AnswerResponse> {
        let response = await fetch(`${this.baseUrl}/questions/${piece.date}/answer?answer=${answer}&key=${this.getKey()}&version=2`);
        let json = await response.json();
        return json;
    }

    getPieceUrl(url: string, x: number, y: number) {
        return `${this.baseUrl}/piece/${url}?x=${x}&y=${y}&key=${this.getKey()}`;
    }

    getSecretUrl(url: string) {
        return `${this.baseUrl}/piece/${url}?key=${this.getKey()}`;
    }
}

export interface AnswerResponse {
    ok: boolean;
    questionState: string;
    puzzle: any;
}

export const puzzleServerClient = new PuzzleServerClient();

